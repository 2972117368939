<template>
  <v-card >
    <v-card-text>
      <h3 class="mb-5">{{ title }}</h3>
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            @click="sendCampagne()"
            >{{ $t("Versturen naar") }}
            <span class="font-weight-bold mx-1">{{ amountOfUsers }}</span
            >{{ $t("contactpersonen") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import request from "../../request";
export default {
  props: ["amountOfUsers", "title", "urlItem"],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    sendCampagne() {
      this.isLoading = true;
      request.post(this.urlItem, null, (res) => {
        if (res) {
          this.isLoading = false;
          this.$emit('setSnackbar',{text:"Campagne goed verstuurd",color:"success"})
        }
        else{
            this.$emit('setSnackbar',{text:"Oeps, er ging iets mis!",color:"error"})

        }
      });
    },
  },
};
</script>

<style>
</style>