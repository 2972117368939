<template>
  <v-card class="mb-4">
    <v-card-text>
      <h3 class="mb-5">{{ title }}</h3>
      <v-row no-gutters>
        <v-col cols="12">
          <input
            :ref="'excelInput'"
            class="fileInput"
            type="file"
            @change="onFileChange($event.target.files)"
          />
          <v-btn
            @click="$refs['excelInput'].click()"
            outlined
            :disabled="uploadingExcel"
          >
            {{ loadbtn }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          v-if="uploadingExcel"
          :class="uploadingExcel ? 'mt-4' : ''"
        >
          {{ $t("Uploading may take a while.") }}
        </v-col>
        <v-col cols="12">
          <v-btn
            :disabled="!selectedExcel"
            :loading="uploadingExcel"
            :class="uploadingExcel ? 'mt-1' : 'mt-4'"
            @click="submitExcel()"
            >{{ $t("Upload") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import request from "../../request";

export default {
  props: ["urlItem", "title", "loadbtn"],
  data() {
    return {
      uploadingExcel: null,
      selectedExcel: null,
    };
  },
  methods: {
    onFileChange(files) {
      this.selectedExcel = files[0];
    },
    submitExcel() {
      this.uploadingExcel = true;

      let formData = new FormData();
      formData.append("file", this.selectedExcel);
      request.post(this.urlItem, formData, (res) => {
        this.uploadingExcel = false;
        if (res.success) {
          this.$refs["excelInput"].value = null;
        }
      });
    },
  },
};
</script>

<style>
</style>