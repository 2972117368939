import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './auth'
import request from './request'
import store from "./store"
import vuexI18n from 'vuex-i18n';

//vuetify
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'


//shared components
import addButton from './components/shared/AddButton'
import addRemovePageElement from './components/shared/AddRemovePageElement'
import backButton from './components/shared/BackButton'
import cancelConfirmButtons from './components/shared/CancelConfirmButtons'
import ConfigLanguageSelector from './components/shared/ConfigLanguageSelector'
import datepicker from './components/shared/DatePicker'
import filterComponent from './components/shared/LeftFilter'
import languageSelector from './components/shared/LanguageSelector'
import pageElement from './components/shared/PageElement'
import pageNameLanguageSelector from './components/shared/PageNameLanguageSelector'
import preloader from './components/shared/Preloader'
import timepicker from './components/shared/TimePicker'
import excelUploadCard from './components/shared/ExcelUploadCard'
import campagneCard from './components/shared/CampagneCard'

//page components
import EditPage from './components/page/EditPage'
import EditPageItem from './components/page/EditPageItem'
import EditPageDetails from './components/page/EditPageDetails'
import DeletePageItem from './components/page/DeletePageItem'
import NotFoundPage from './components/page/NotFoundPage'

//login components
import ActivateAccount from './components/login/ActivateAccount'
import ForgotPassword from './components/login/ForgotPassword'
import Login from './components/login/Login'
import ResetPassword from './components/login/ResetPassword'

//website components
import CreateEditWebsite from './components/website/CreateEditWebsite'
import DeleteWebsite from './components/website/DeleteWebsite'
import ManageWebsites from './components/website/ManageWebsites'
import Settings from './components/website/Settings'
import Translations from './components/website/Translations'

//user components
import CreateEditUser from './components/user/CreateEditUser'
import DeleteUser from './components/user/DeleteUser'
import ManageUsers from './components/user/ManageUsers'

//other components
import App from './components/App'
import Dashboard from './components/Dashboard'
import RouteNotAuthorized from './components/RouteNotAuthorized'

Vue.use(VueRouter)
Vue.use(vuexI18n.plugin, store)
Vue.use(Vuetify);

//vue components
Vue.component('addButton', addButton)
Vue.component('addRemovePageElement', addRemovePageElement)
Vue.component('backButton', backButton)
Vue.component('cancelConfirmButtons', cancelConfirmButtons)
Vue.component('languageSelector', languageSelector)
Vue.component('pageElement', pageElement)
Vue.component('pageNameLanguageSelector', pageNameLanguageSelector)
Vue.component('preloader', preloader)
Vue.component('login', Login)
Vue.component('date-picker', datepicker)
Vue.component('time-picker', timepicker)
Vue.component('filterComponent', filterComponent)
Vue.component('configLanguageSelector', ConfigLanguageSelector)
Vue.component('excelUploadCard', excelUploadCard)
Vue.component('campagneCard', campagneCard)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/' },
    { path: '/routenotauthorized', component: RouteNotAuthorized, name: 'routeNotAuthorized', meta: { adminRoute: false } },
    { path: '/dashboard', component: Dashboard, name: 'dashboard', meta: { adminRoute: false } },
    { path: '/websitesoverview', component: ManageWebsites, name: 'websites', meta: { adminRoute: true } },
    { path: '/translations', component: Translations, name: 'translations', meta: { adminRoute: true } },
    { path: '/login', component: Login, name: "login", meta: { adminRoute: false } },
    { path: '/websitesoverview/deletewebsite/:id', component: DeleteWebsite, name: 'deleteWebsite', meta: { adminRoute: true } },
    { path: '/websitesoverview/createeditwebsite', component: CreateEditWebsite, name: 'createNewWebsite', meta: { adminRoute: true } },
    { path: '/websitesoverview/createeditwebsite/:id', component: CreateEditWebsite, name: 'createEditWebsite', meta: { adminRoute: true } },
    { path: '/usersoverview', component: ManageUsers, name: 'manageUsers', meta: { adminRoute: true } },
    { path: '/usersoverview/deleteuser/:id', component: DeleteUser, name: 'deleteUser', meta: { adminRoute: true } },
    { path: '/usersoverview/createedituser', component: CreateEditUser, name: 'createNewUser', meta: { adminRoute: true } },
    { path: '/usersoverview/createedituser/:id', component: CreateEditUser, name: 'createEditUser', meta: { adminRoute: true } },
    { path: '/page/editpagedetails/:name/:websiteId/:id', component: EditPageDetails, name: 'editPageDetails', meta: { adminRoute: true } },
    { path: '/page/editpage/:id', component: EditPage, name: 'editPage', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/addedititem/', component: EditPageItem, name: 'editPageAddItem', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/addedititem/:itemId', component: EditPageItem, name: 'editPageAddEditItem', meta: { adminRoute: false } },
    { path: '/page/editpage/:id/deletepageitem/:itemId', component: DeletePageItem, name: 'deletePageItem', meta: { adminRoute: false } },
    { path: '/forgotpassword', component: ForgotPassword, name: 'forgotPassword', meta: { adminRoute: false } },
    { path: '/resetpassword/:hash', component: ResetPassword, name: 'resetPassword', meta: { adminRoute: false } },
    { path: '/activateaccount/:hash', component: ActivateAccount, name: 'activateAccount', meta: { adminRoute: false } },
    { path: '/settings', component: Settings, name: 'settings', meta: { adminRoute: true } },
    { path: '/pagenotfound', component: NotFoundPage, name: 'pagenotfound', meta: { adminRoute: false } },
    { path: '*', component: NotFoundPage, name: 'notFoundPage', meta: { adminRoute: false } }
  ]

})


const requests = [
  fetch(new Request("/config.json")),
  fetch(new Request("/translationsNl.json")),
  fetch(new Request("/translationsFr.json")),
  fetch(new Request("/translationsEn.json"))
]

Promise.all(requests).then(allResponses => {
  if (allResponses[0]) {
    allResponses[0].json()
      .then((config) => {
        Vue.prototype.$config = config
        const vuetify = new Vuetify({
          theme: {
            themes: {
              light: {
                primary: config.primaryColor,
                secondary: config.secondaryColor
              },
            },
            options: {
              customProperties: true,
            },

          },
          icons: {
            iconfont: 'mdi',
          }
        });
        request.get('/validatesession', {}, (res) => {
          if (res.success) {
            auth.setUser(res.data)
          } else {
            auth.deleteUser()
          }


          router.beforeEach((to, from, next) => {
            //redirect user to login if user is not logged in
            if (!auth.loggedIn()
              && to.path !== '/login'
              && to.path !== '/forgotpassword'
              && to.path.indexOf("/resetpassword/") == -1
              && to.path.indexOf("/activateaccount/") == -1) {
              next('/login')
            }
            //redirect to dashboard if user is loggedIn
            else if (auth.loggedIn() && to.path == '/login') {
              next('/dashboard')
            }
            //notauthorized
            else if (to.meta.adminRoute && !auth.userIsAdministrator()) {
              next('/routenotauthorized')
            }
            //redirect user after login to "homepage" or dashboard
            else if (to.fullPath === "/") {
              request.get("/websites", null, (res) => {
                if (res.success) {
                  (res.data[0].DashboardPageId.$oid !== "000000000000000000000000") ?
                    next(`/page/editpage/${res.data[0].DashboardPageId.$oid}`) :
                    next('/dashboard')
                } else {
                  next('/dashboard')
                }
              })
            }
            //needs next to go to the next page if previus cases dont work
            else {
              next()
            }
          });

                  new Vue({
            el: '#app',
            router,
            vuetify,
            store,
            render: h => h(App),
          })
        })
        /* }
        else {
          alert("Geen config file!");
        } */
      })
  }
  if (allResponses[1]) {
    allResponses[1].json()
      .then((langNl) => {

        Vue.i18n.add('Nl', langNl);
      })
  }
  if (allResponses[2]) {
    allResponses[2].json()
      .then((langFr) => {

        Vue.i18n.add('Fr', langFr);
      })
  }
  if (allResponses[3]) {
    allResponses[3].json()
      .then((langEn) => {

        Vue.i18n.add('En', langEn);
      })
  }
  if (allResponses[1] ||
    allResponses[2] ||
    allResponses[3]) {

    try {
      const currentLanguage = (navigator.language || navigator.userLanguage).split("-")[0]

      if (auth.userLanguage() != "") {
        Vue.i18n.set(auth.userLanguage())
      } else if (currentLanguage == "Fr") {
        Vue.i18n.set('Fr')
      }
      else if (currentLanguage == "En") {
        Vue.i18n.set('En')
      }
      else {
        Vue.i18n.set('Nl')
      }
    }
    catch (err) {
      Vue.i18n.set('Nl')
    }
  }
})





